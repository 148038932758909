@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,400italic)

$menu_bg: rgb(0,0,0)
$menu_blue_lighter: rgb(94, 148, 186)
$break_large: 1200px
$break_medium: 940px
$break_small: 500px

@mixin css3-prefix($property, $value)
  -webkit-#{$property}: #{$value}
  -khtml-#{$property}: #{$value}
  -moz-#{$property}: #{$value}
  -ms-#{$property}: #{$value}
  -o-#{$property}: #{$value}
  #{$property}: #{$value}

html
  overflow: -moz-scrollbars-vertical
  overflow-y: scroll

body
  font-family: 'Source Sans Pro', sans-serif
  font-size: 15px
  width: 100%
  height: 100%

h1
  font-size: 30px
  font-weight: 700
  margin: 70px 0 55px 0

h2
  font-weight: 700

.login_form
  width: 400px
  margin: 100px auto
  @media screen and (max-width: $break_small)
    margin: 40px 60px
    width: initial
  .logo
    text-align: center
    margin-bottom: 50px
    .slash
      color: rgb(12, 200, 255)

#grandgoal_app
  margin: 0

.outer_container
  width: 1200px
  margin: 0 auto
  outline: none
  #main_container
    background-color: white
    width: 900px
    margin: 0 auto 0 auto
    @media screen and (max-width: $break_large)
      width: initial
      margin: 70px 10px 0 10px
    @media screen and (max-width: $break_small)
      margin: 0
      width: initial
    .main_container
      @media screen and (max-width: $break_small)
        margin: 0 10px
  @media screen and (max-width: $break_large)
    width: 900px
    margin: 0 auto
  @media screen and (max-width: $break_medium)
    width: 100%
    margin: 0


ul.menu_main
  width: 100px
  background-color: white
  padding: 0
  list-style: none
  text-transform: uppercase
  position: fixed
  top: 70px
  margin-left: 20px
  z-index: 100
  @media screen and (max-width: $break_large)
    width: 100%
    top: 0
    margin: 0 10px
  @media screen and (max-width: $break_small)
    display: none

  li
    margin: 4px 0
    @media screen and (max-width: $break_large)
      display: inline-block
      margin: 10px 20px 10px 0
    a
      padding: 1px 5px
      color: hsl(0, 0, 70%)
      display: inline-block
      &:focus, &:active, &:hover
        text-decoration: none
      &:hover
        color: black
    &.active a
      color: white
      background-color: black

  .logo
    color: white
    font-weight: 700
    text-transform: uppercase
    letter-spacing: 2px


ul.menu_touch
  width: 100px
  background-color: white
  padding: 0
  list-style: none
  text-transform: uppercase
  position: fixed
  top: 16px
  margin-left: 20px
  display: none
  li
    margin: 16px 0
    a
      padding: 1px 5px
      color: hsl(0, 0, 70%)
      display: inline-block
      &:focus, &:active, &:hover
        text-decoration: none
      &:hover
        color: black
    &.active a
      color: white
      background-color: black

.menu_inbox_name
  vertical-align: middle

.menu_inbox_count
  background-color: #e60808
  color: white
  border-radius: 0.7em
  line-height: 1.5em
  font-size: 0.7em
  padding: 0
  width: 1.5em
  height: 1.5em
  display: inline-block
  text-align: center
  vertical-align: baseline
  margin-left: 0.7em
  font-weight: 400


.edit_link
  font-size: 80%
  color: hsl(0, 0, 50%)
  &:hover
    color: hsl(0, 0, 30%)

.recordlist
  margin: 50px 0
  .titlebar
    margin-bottom: 10px
    h3
      font-size: 140%
      font-weight: 300
      text-transform: uppercase
      display: inline-block
      margin-right: 20px
    span.list_type
      text-transform: uppercase
      color: rgb(196, 69, 0)
      font-size: 65%
      margin-right: 20px
      padding: 1px 7px
      border-radius: 5px
      white-space: nowrap
    a
      font-size: 70%
      color: hsl(0, 0, 80%)
      text-decoration: none
      &:hover
        color: hsl(0, 0, 70%)
  ul
    list-style: none
    padding: 0

    .record
      margin: 5px 0
      padding-left: 20px
      @media screen and (max-width: $break_large)
        margin: 12px 0

      .checkbox
        margin-left: -20px
        float: left

      .content
        display: inline-block

      &.done
        text-decoration: line-through
        color: hsl(0, 0, 80%)
        &.opened
          text-decoration: none
          color: black
        .tags, .list_type, .planned_for
          color: hsl(0, 0, 80%)

      &.opened
        border-bottom: 1px solid hsl(0, 0, 80%)
        margin-bottom: 20px
        padding-bottom: 20px
        padding-left: 0

        input, textarea
          border: 1px solid hsl(0, 0, 95%)
          &:focus
            border: 1px solid hsl(0, 0, 90%)
            outline: none
            box-shadow: none
        .first_line
          width: 100%
          padding: 0 50px 0 30px
          .done_checkbox
            margin-left: -30px
          .title
            font-weight: 700
            width: 100%
            margin-left: 5px
          .close_button
            margin-right: -50px
            float: right
            color: hsl(0, 0, 50%)
            padding: 4px 9px
            background-color: hsl(0, 0, 95%)
            font-size: 80%
        .menu
          margin: 10px 0 10px 0
          text-align: right
          .type, .project
            margin-left: 10px
          .tags
            margin: 2px 0 12px 0
            width: 100%
          @media screen and (max-width: $break_small)
            .list, .type, .project
              margin: 0
            .list, .type
              width: 25%
            .project
              width: 50%

        .tags, .title
          cursor: text
          color: black
          font-size: 100%
        .text_wrapper
          textarea
            width: 100%
            overflow: hidden
            padding: 5px 5px 10px 5px
        .project, .type, .list
          width: initial
          display: inline-block
          margin-top: 2px
          margin-bottom: 2px

      .title
        cursor: pointer
      .text_teaser
        margin-left: 10px
        color: hsl(0, 0, 70%)
        font-size: 80%

      a.project
        color: hsl(0, 0, 50%)
        background-color: hsl(0, 0, 93%)
        font-size: 65%
        margin-left: 10px
        cursor: pointer
        padding: 0px 5px 1px 5px
        border-radius: 5px
        white-space: nowrap
        &:hover, &:focus
          text-decoration: none

      .tags
        color: hsl(0, 0, 50%)
        font-size: 75%
        margin-left: 10px

      span.planned_for
        color: rgb(196, 69, 0)
        font-size: 65%
        margin-left: 10px
        padding: 1px 7px
        border-radius: 5px
        white-space: nowrap
        i
          font-size: 90%
          margin-right: 5px

      span.list_type, span.record_type
        text-transform: uppercase
        color: rgb(196, 69, 0)
        font-size: 65%
        margin-left: 10px
        padding: 1px 7px
        border-radius: 5px
        white-space: nowrap

  .add_button
    font-size: 75%
    font-weight: 700
    text-transform: uppercase
    color: hsl(0, 0, 70%)
    text-decoration: none
    @media screen and (max-width: $break_medium)
      padding: 4px 10px
      border: 1px solid hsl(0, 0, 90%)
      border-radius: 3px
      display: inline-block
      margin-top: 10px
      font-weight: normal

.projectlist ul li.record
  margin: 6px 0
  padding: 0
  a.title
    color: black
    font-weight: bold
    margin-left: 0
    &:hover, &:focus
      text-decoration: none

.secondary_menu
  text-align: right
  margin-top: 10px
  .item_action
    margin-left: 20px
    font-size: 90%
    color: hsl(0, 0, 70%)
    text-decoration: underline
    &:hover
      color: hsl(0, 0, 70%)
      text-decoration: none


// react-textselect START
.react-textselect
  border-bottom: 1px solid rgba(0, 0, 0, .33)
  overflow: hidden
  padding-right: 1em
  margin-right: .17em
  cursor: pointer
  position: relative

  &::after
    position: absolute
    top: .45em
    right: 0
    display: block
    content: ''
    height: 0
    width: 0
    border: .33em solid transparent
    border-top: .33em solid rgba(0, 0, 0, .33)

  &-input
    position: absolute
    top: 0
    left: 0
    right: 0
    width: 100%
    opacity: 0
    z-index: 1
// react-textselect END


ul.tagselector
  list-style: none
  padding: 0
  margin: 30px 0 0 0
  li
    display: inline-block
    margin: 3px 15px 3px 0
    a
      padding: 3px 6px
      color: hsl(0, 0, 70%)
      &:focus, &:active, &:hover
        text-decoration: none
      &:hover
        color: black
    &.selected a, &.selected.context a
      background-color: black
      color: white
    &.context a
      background-color: rgb(239, 246, 255)


// slideout
.slideout-menu
  position: fixed
  left: 0
  top: 0
  bottom: 0
  right: 0
  z-index: 0
  width: 256px
  overflow-y: auto
  -webkit-overflow-scrolling: touch
  display: none

.slideout-panel
  position: relative
  z-index: 1

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel
  overflow: hidden

.slideout-open .slideout-menu
  display: block



.Menu
  display: inline-block
  position: relative
  z-index: 1000

.Menu__MenuOptions
  // border: 1px solid hsl(0, 0, 95%)
  // border-radius: 3px
  background: #FFF
  position: absolute
  box-shadow: 2px 2px 11px 0px rgba(0,0,0,0.28)
  min-width: 150px

.Menu__MenuOption
  padding: 6px 10px
  border-radius: 2px
  outline: none
  cursor: pointer
  text-align: left

.Menu__MenuOption--active
  background-color: hsl(0, 0, 95%)

.Menu__MenuTrigger
  padding: 6px 10px
  background: #FFF
  display: inline-block
  text-decoration: underline
  &:hover
    text-decoration: none

.Menu__MenuOptions--horizontal-left
  right: 0px

.Menu__MenuOptions--horizontal-right
  left: 0px

.Menu__MenuOptions--vertical-top
  bottom: 45px

.Menu__MenuOptions--vertical-bottom


.plan_for_menu
  .day
    font-weight: bold
    color: black
  .description
    color: hsl(0, 0, 70%)
    margin-left: 7px

.project_review
  border-right: 1px solid lightgray
  tr
    border-bottom: 1px solid lightgray
  .project_title
    padding-right: 30px
  .done_tasks
    padding: 0 4px
    background-color: black
    color: black
  .done_tasks_0
    background-color: white
    color: white
  .done_tasks_1
    background-color: lightgray
    color: lightgray
  .done_tasks_2
    background-color: gray
    color: gray
